<template>
  <div
    class="accordion"
    role="tablist"
  >
    <b-card
      no-body
      class="mb-1"
    >
      <b-card-header
        header-tag="header"
        class="p-1"
        role="tab"
      >
        <div
          v-b-toggle.accordion-1
          class="w-100 d-flex justify-content-between align-items-center"
          block
        >
          <b-badge
            variant="light-primary"
            class="font-small-4 px-1 py-50"
          >
            BÁO CÁO
          </b-badge>
          <feather-icon
            icon="ChevronRightIcon"
            size="22"
          />
        </div>
      </b-card-header>
      <b-collapse
        id="accordion-1"
        accordion="my-accordion"
        role="tabpanel"
      >
        <b-card-body class="pt-0">
          <b-row>
            <b-col
              cols="12"
              class="mb-md-0 mb-50"
            >
              <label>Thời gian</label>
              <div class="d-flex align-items-center">
                <DatePicker
                  v-model="filterDateRange"
                  placeholder="Chọn ngày"
                  format="DD/MM/YYYY"
                  language="vi"
                  input-class="form-control"
                  wrapper-class="w-100"
                  range
                />
              </div>
            </b-col>
          </b-row>
        </b-card-body>
        <div class="py-1 px-2 d-flex justify-content-between">
          <div v-html="'Tìm thấy ' + (totalActivity || 0) + ' kết quả'" />
        </div>
        <b-table
          ref="refActivityListTable"
          class="position-relative custom-head thead-no-wrap line-height-12 font-small-4"
          responsive
          hover
          primary-key="id"
          show-empty
          small
          empty-text="Không có kết quả nào"
          :items="activityList"
          :fields="tableColumns"
        >
          <template #cell(ngay-thang)="data">
            <div class="text-primary">
              {{ moment(data.item.created_at).format('HH:ss:mm DD/MM/YYYY') }}
            </div>
          </template>
          <template #cell(hanh-dong)="data">
            <div>{{ hashAction[data.item.action] }}</div>
          </template>
          <template #cell(doi-tuong)="data">
            <div @click="getRedirectAndTitlePayload(data.item).action && getRedirectAndTitlePayload(data.item).action()">
              {{ getRedirectAndTitlePayload(data.item) && getRedirectAndTitlePayload(data.item).title }}
            </div>
          </template>
          <template #cell(thiet-bi)="data">
            <div>{{ data.item.device }}</div>
          </template>
        </b-table>

        <div class="mb-3">
          <b-row>
            <!-- Per Page -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start mb-1 mb-md-0"
            />

            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalActivity"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-collapse>
    </b-card>
  </div>
</template>
<script>
import moment from 'moment'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import userStoreModule from '@/views/user/userStoreModule'
import useUserDetail from '@/views/user/detail/useUserDetail'
import DatePicker from 'vue2-datepicker'

import 'vue2-datepicker/locale/vi'
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCollapse,
  BBadge,
  BRow,
  BCol,
  BPagination,
  BTable,
} from 'bootstrap-vue'

const TYPE_PRODUCT = [
  {
    text: 'Tất cả',
    value: 'all',
    validBy: ['thue', 'mua'],
  },
  {
    text: 'Nhà mặt tiền',
    value: 'nha-mat-tien',
    validBy: ['thue', 'mua', 'cho-thue', 'ban'],
  },
  {
    text: 'Nhà hẻm',
    value: 'nha-hem',
    validBy: ['thue', 'mua', 'cho-thue', 'ban'],
  },
  {
    text: 'Khách sạn, CHDV',
    value: 'khach-san',
    validBy: ['thue', 'cho-thue', 'ban', 'mua'],
    // validBy: [],
  },
  // {
  //   text: 'Khách sạn, CHDV',
  //   value: 'khach-san',
  //   validBy: ['ban'],
  // },
  {
    text: 'Kho xưởng',
    value: 'warehouse',
    validBy: ['thue', 'cho-thue'],
  },
  {
    text: 'Văn phòng',
    value: 'van-phong',
    validBy: [],
  },
  {
    text: 'ShopHouse',
    value: 'shop-house',
    validBy: [],
  },
  {
    text: 'Chung cư',
    value: 'chung-cu',
    validBy: [],
  },
  {
    text: 'Sân thượng',
    value: 'san-thuong',
    validBy: ['thue', 'cho-thue'],
  },
  {
    text: 'Sang nhượng',
    value: 'sang-nhuong',
    validBy: ['thue', 'cho-thue'],
  },
]

const OFFICE_DIRECTION = [
  {
    value: 'dong',
    text: 'Đông',
    textEN: 'East',
  },
  {
    value: 'tay',
    text: 'Tây',
    textEN: 'Ưest',
  },
  {
    value: 'nam',
    text: 'Nam',
    textEN: 'South',
  },
  {
    value: 'bac',
    text: 'Bắc',
    textEN: 'North',
  },
  {
    value: 'dong-bac',
    text: 'Đông Bắc',
    textEN: 'NorthEast',
  },
  {
    value: 'dong-nam',
    text: 'Đông Nam',
    textEN: 'SouthEast',
  },
  {
    value: 'tay-bac',
    text: 'Tây Bắc',
    textEN: 'NorthWest',
  },
  {
    value: 'tay-nam',
    text: 'Tây Nam',
    textEN: 'SouthWest',
  },
]
export default {
  name: 'UserDetailActivity',
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCollapse,
    BBadge,
    BRow,
    BCol,
    BPagination,
    DatePicker,
    BTable,
  },
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      created: false,
      moment,
      hashAction: {
        login: 'Đăng nhập',
        list: 'Tìm kiếm',
        'list-detail': 'Xem chi tiết',
      },
      hashDirection: OFFICE_DIRECTION.reduce((acc, item) => {
        acc[item.value] = item.text
        return acc
      }, {}),
    }
  },
  mounted() {
    window.__userDetailActivity = this
  },
  created() {
    // this.filter.product_type = 'townhouse'
    this.fetchActivity().finally(() => {
      this.loading = false
    })
    // waiting for watching done
    setTimeout(() => { this.created = true }, 500)
  },
  methods: {
    getRedirectAndTitlePayload(item) {
      const {
        action, payload, location, type,
      } = item
      const {
        product_type, formality, type: payloadType,
      } = payload
      if (action === 'list-detail') {
        return {
          title: typeof payload === 'object' ? payload.title : payload,
          action: () => {
            this.goToDetail({
              name: this.hashNameRouterByType(product_type, formality),
              params: {
                id: payload.id,
              },
            })
          },
        }
      }
      if (action === 'list') {
        return {
          title: `Tìm kiếm ${this.hashTypeByValue(product_type)} ${this.hashFormalityByValue(formality)}${this.parseType(payloadType)}${this.parsePayload(payload)}`,
        }
      }
      if (action === 'login') {
        return {
          title: `Vị trí: ${location}`,
        }
      }
      return {}
    },
    hashTypeByValue(productType) {
      const hashProductType = {
        all: 'tất cả nhà phố, ks, chdv và văn phòng',
        office: 'văn phòng',
      }
      return hashProductType[productType] || 'nhà phố, ks, chdv'
    },
    hashFormalityByValue(formality) {
      const hashFormality = {
        'cho-thue': 'cho thuê',
        ban: 'bán',
      }
      return hashFormality[formality] ? `${hashFormality[formality]} ` : ''
    },
    parseType(type) {
      const hash = {
      }
      // eslint-disable-next-line no-undef
      TYPE_PRODUCT.forEach(item => {
        hash[item.value] = item.text
      })
      return hash[type] ? `loại ${hash[type]} ` : ''
    },
    parsePayload(payload) {
      const keys = ['direction', 'class', 'street_name_in', 'house_number', 'has_corner', 'width', 'length', 'acreage', 'price_from', 'price_to']
      const res = []
      keys.forEach(key => {
        if (payload[key]) {
          if (key === 'direction') {
            res.push(`hướng ${this.hashDirection[payload[key]]}`)
          }
          if (key === 'class') {
            res.push(`hạng ${payload[key]}`)
          }
          if (key === 'street_name_in') {
            res.push(`có vị trí nằm trên đường ${payload[key].join(', ')}`)
          }
          if (key === 'house_number') {
            res.push(`có số nhà (chứa) ${payload[key]}`)
          }
          if (key === 'has_corner') {
            res.push('có góc')
          }
          if (key === 'width') {
            res.push(`có chiều ngang tối thiểu ${payload[key]}m`)
          }
          if (key === 'length') {
            res.push(`có chiều dài tối thiểu ${payload[key]}m`)
          }
          if (key === 'acreage') {
            res.push(`có diện tích tối thiểu ${payload[key]}m2`)
          }
          if (key === 'price_from') {
            res.push(`có giá tối thiểu ${payload[key]}`)
          }
          if (key === 'price_to') {
            res.push(`có giá tối đa ${payload[key]}`)
          }
        }
      })
      return res.length ? `${res.join(', ')}` : ''
    },
    hashNameRouterByType(productType, formality) {
      if (productType === 'office') return 'product-office-detail'
      const hash = {
        'cho-thue': 'product-hired-detail',
        ban: 'product-sell-detail',
      }
      return hash[formality]
    },
    goToDetail({ name, params }) {
      const routeData = this.$router.resolve({ name, params })
      window.open(routeData.href, '_blank')
    },
  },
  setup(props) {
    const USER_STORE_MODULE_NAME = 'user'
    if (!store.hasModule(USER_STORE_MODULE_NAME)) store.registerModule(USER_STORE_MODULE_NAME, userStoreModule)
    onUnmounted(() => {
      if (store.hasModule(USER_STORE_MODULE_NAME)) store.unregisterModule(USER_STORE_MODULE_NAME)
    })
    console.log('gooo thereeeee', props)
    return {
      ...useUserDetail({
        userId: props.userId,
      }),
    }
  },
}
</script>
<style lang="scss" scoped>
.card-header {
    svg {
        transition: transform 0.3s;
    }
    .not-collapsed {
        svg {
            transform: rotate(90deg);
        }
    }
}

</style>

<style lang="scss">
$namespace: 'mx'; // change the 'mx' to 'xmx'. then <date-picker prefix-class="xmx" />

$default-color: #555;
$primary-color: #1284e7;

@import '~vue2-datepicker/scss/index.scss';
</style>
